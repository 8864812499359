import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["sharing", "download", "downloadBipOptions"]

  showSharing() {
    this.sharingTarget.classList.remove("hidden")
    this.downloadTarget.classList.add("hidden")
  }

  showDownload() {
    this.downloadTarget.classList.remove("hidden")
    this.sharingTarget.classList.add("hidden")
  }

  downloadPdf(e) {
    const downloadBipOptions = this.downloadBipOptionsTarget.querySelector("input[name='download_bip_options']:checked").value
    if (downloadBipOptions) {
      e.preventDefault()
      const url = e.target.href + `?${downloadBipOptions}`
      window.open(url, "_blank").focus();
    }
  }
}
